import { createI18n } from 'vue-i18n';
import enUs from './../../backend-src/App/Config/i18n/en-US.json';
const en = enUs['frontend'];
// https://vue-i18n.intlify.dev/guide/advanced/optimization#improve-performance-and-reduce-bundle-size-with-runtime-build-only

/*
const i18n = createI18n({
    legacy: false,
    locale: window.thesudokuapp.i18n.c,
    messages: window.thesudokuapp.i18n.l,
    warnHtmlMessage: false
    //warnHtmlInMessage: 'off' когда legacy: true
});*/

//import messages from '@intlify/unplugin-vue-i18n/messages';

const
    i18n = createI18n({
        legacy: false,
        locale: 'en',
        messages: { en },
        warnHtmlMessage: false
        //globalInjection: true
    }),
    $t = i18n.global.t;

export { i18n, $t };
