'use strict';

const cols = '123456789';
const rows = 'ABCDEFGHI';

const cross = (a, b) => {
    const result = [];

    for (const A of a) {
        for (const B of b) {
            result.push(A + B);
        }
    }

    return result;
};

/* [A1,...,A9,B1,...,B9,...,I1,...,I9] */
const cells = cross(rows, cols);

/* push order matters! (Board.vue, thesudoku-solver -> removePointingPairs) */
let unitlist = [];

/* [A1,A2,A3,B1,B2,B3,C1,C2,C3],...,[G7,G8,G9,H7,H8,H9,I7,I8,I9] */
for (const unitRow of [['A','B','C'],['D','E','F'],['G','H','I']]) {
    for (const unitCol of [[1,2,3],[4,5,6],[7,8,9]]) {
        unitlist.push(cross(unitRow, unitCol));
    }
}

/* [A1,..,A9],...,[I1,...,I9] */
for (const rowIndex of rows) {
    unitlist.push(cross(rowIndex, cols));
}

/* [A1,B1,...,I1],...,[A9,B9,...,I9] */
for (const colIndex of cols) {
    unitlist.push(cross(rows, colIndex));
}

const
    peers = [],
    units = [];

/* peers: A1->(B1,...,I1)+(A2,...,A9)+(A2,A3,B1,B2,B3,C1,C2,C3) -> список всех элементов блока, строки и колонки заданной ячейки
 * units: A1->0->[A1..A9],1->[A1..I9],2->[A1,A2,A3...C3] -> список всех юнитов в которых присутствует данная ячейка
 **/

for (const cell of cells) {
    peers[cell] = new Set([].concat(...unitlist.filter(unit => unit.includes(cell))));
    peers[cell].delete(cell);
    units[cell] = unitlist.filter(unit => unit.includes(cell));
}

export { cells, peers, units, unitlist };
