import { cells, peers, units, unitlist } from './thesudokuapp-peers.mjs';

/**
 * '3001...'-> [ 'A1' => 3, 'A2' => 0, 'A3' => '0', 'A4' => 1...]
 */
const convertRawLayoutToGrid = grid => {
    const result = new Map();

    for (const [ index, cell ] of cells.entries()) {
        //result[cell] = grid[index];
        result.set(cell, grid[index]);
    }

    return result;
};

const prepareDataForHintsSearch = ({ selectedValues, selectedHints, predefinedValues, solutionValues }) => {
    const gridToSolve = new Map();
    const wrongValues = new Set();
    const hintsUnfilled = new Set();
    const parsedGrid = new Map();
    let rawGrid = '';

    Object.entries(selectedValues).forEach(([cellId, valueId]) => {
        const predefinedValue = predefinedValues[cellId];
        const solutionValue = solutionValues[cellId];

        if (!predefinedValue && valueId && valueId !== solutionValue) {
            wrongValues.add(cellId);
            gridToSolve.set(cellId, '0');
            parsedGrid.set(cellId, new Set());
            rawGrid += '0';
        } else {
            gridToSolve.set(cellId, (valueId || predefinedValue).toString());
            parsedGrid.set(cellId, new Set((valueId || predefinedValue).toString()));
            rawGrid += (valueId || predefinedValue).toString();
        }

        if (!predefinedValue && !valueId) {
            const hints = new Set(selectedHints[cellId].reduce((acc, bHintOn, index) => {
                if (bHintOn) {
                    acc.push(index.toString());
                }
                return acc;
            }, []));

            if (!hints.size) {
                hintsUnfilled.add(cellId);
            }

            parsedGrid.set(cellId, hints);
        }
    });

    return {
        wrongValues,
        gridToSolve,
        hintsUnfilled,
        parsedGrid,
        rawGrid
    };
};

const checkHints = (selectedValues, selectedHints, predefinedValues, solutionValues) => {
    for (const [cellId, hintsBooleanArray] of Object.entries(selectedHints).filter(([cellId]) => !(predefinedValues[cellId] || selectedValues[cellId]))) {
        const hintsValuesSet = new Set(hintsBooleanArray.reduce(
            (acc, currentValue, index) => {
                if (currentValue) {
                    acc.push(index);
                }

                return acc;
            },
        []));

        if (!hintsValuesSet.has(solutionValues[cellId])) {
            return {
                missingHint: {
                    hintCellId: cellId,
                    hint: solutionValues[cellId]
                }
            };
        }

        for (const hint of hintsValuesSet) {
            for (const [unitIndex, unit] of unitlist.entries()) {
                if (unit.includes(cellId)) {
                    const filteredValues = unit.reduce(
                        (acc, currentCellId) => {
                            if (selectedValues[currentCellId] || predefinedValues[currentCellId]) {
                                acc.set(currentCellId, selectedValues[currentCellId] || predefinedValues[currentCellId]);
                            }

                            return acc;
                        },
                        new Map());

                    for (const [valueCellId, value] of filteredValues) {
                        if (value === hint) {
                            return {
                                wrongHint: {
                                    hintCellId: cellId,
                                    valueCellId,
                                    hint,
                                    unitIndex,
                                    unit
                                }
                            };
                        }
                    }
                }
            }
        }
    }

    return {};
};

const getHint = ({ selectedValues, selectedHints, predefinedValues, solutionValues }) => {
    const {
        wrongValues,
        gridToSolve,
        hintsUnfilled,
        parsedGrid,
        rawGrid
    } = prepareDataForHintsSearch({ selectedValues, selectedHints, predefinedValues, solutionValues });

    if (wrongValues.size) {
        return {
            wrongValues
        };
    }

    if (hintsUnfilled.size) {
        const reparsedGrid = parseRaw(rawGrid, 0);

        const hiddenSingles = removeHidden(reparsedGrid, 2, gridToSolve, true);

        if (hiddenSingles) {
            return {
                hiddenSingles
            };
        }

        const nakedSingles = removeNaked(reparsedGrid, 1, gridToSolve, true);

        if (nakedSingles) {
            return {
                nakedSingles
            };
        }

        return {
            hintsUnfilled
        };
    }

    const {
        wrongHint,
        missingHint
    } = checkHints(selectedValues, selectedHints, predefinedValues, solutionValues);

    if (wrongHint || missingHint) {
        return {
            wrongHint,
            missingHint
        };
    }

    const nakedParsedSingles = removeNaked(parsedGrid, 1, gridToSolve, true);

    if (nakedParsedSingles) {
        return {
            nakedSingles: nakedParsedSingles
        };
    }

    const hiddenParsedSingles = removeHidden(parsedGrid, 2, gridToSolve, true);

    if (hiddenParsedSingles) {
        return {
            hiddenSingles: hiddenParsedSingles
        };
    }

    const nakedPair = removeNaked(parsedGrid, 3, gridToSolve, true);

    if (nakedPair) {
        return {
            nakedPair
        };
    }

    const nakedTriple = removeNaked(parsedGrid, 4, gridToSolve, true);

    if (nakedTriple) {
        return {
            nakedTriple
        };
    }

    const rowsPointingPairs = removeRowsPointingPairs(parsedGrid, 5, gridToSolve, true);

    if (rowsPointingPairs) {
        return {
            rowsPointingPairs
        };
    }

    const columnsPointingPairs = removeColumnsPointingPairs(parsedGrid, 6, gridToSolve, true);

    if (columnsPointingPairs) {
        return {
            columnsPointingPairs
        };
    }

    const boxLineReductionRow = removeBoxLineReduction(parsedGrid, 7, gridToSolve, true);

    if (boxLineReductionRow) {
        return {
            boxLineReductionRow
        };
    }

    const boxLineReductionColumn = removeBoxLineReduction(parsedGrid, 8, gridToSolve, true);

    if (boxLineReductionColumn) {
        return {
            boxLineReductionColumn
        };
    }

    const nakedQuad = removeNaked(parsedGrid, 9, gridToSolve, true);

    if (nakedQuad) {
        return {
            nakedQuad
        };
    }

    const hiddenPair = removeHidden(parsedGrid, 10, gridToSolve, true);

    if (hiddenPair) {
        return {
            hiddenPair
        };
    }

    const hiddenTriple = removeHidden(parsedGrid, 11, gridToSolve, true);
    if (hiddenTriple) {
        return {
            hiddenTriple
        };
    }

    const hiddenQuad = removeHidden(parsedGrid, 12, gridToSolve, true);

    if (hiddenQuad) {
        return {
            hiddenQuad
        };
    }

    return {};
};

const eliminate = (parsedGrid, cellId, hintId, grid = null) => {
    const hints = parsedGrid.get(cellId);

    if (!hints.has(hintId)) {
        return true;
    }

    hints.delete(hintId);

    /* Удалили все подсказки, не осталось ничего, значит никакое значение в ячейке невозможно,
     * значит некорректный грид */
    if (!hints.size) {
        return false;
    }

    /* Второе условие: Если нам передан грид, значит нам не нужно полностью распарсивать, а только заполнить хинты по predefined значениям,
    * а значит если в ячейке осталось только одно возможное значение хинта, то не нужно его убирать из соседних ячеек,
    * убираем только если возможное значение хинта и есть predefined значение */
    //if (hints.size === 1) {
    if (hints.size === 1 && (!grid || (grid.get(cellId) !== '0'))) {
        const cellValue = [...hints][0];

        for (const peerCellId of peers[cellId]) { // ИЗ FOREACH return НЕ РАБОТАЕТ!
            if (!eliminate(parsedGrid, peerCellId, cellValue, grid)) {
                return false;
            }
        }
    }

    /*
    * Если нам передан грид, значит нам не нужно полностью распарсивать, а только заполнить хинты по значениям,
    * а значит и поиск единственного возможного варианта нам не нужен
    */
    if (grid) {
        return true;
    }

    for (const unit of units[cellId]) {
        const dplaces = unit.filter(cellId => parsedGrid.get(cellId).has(hintId));

        if (!dplaces.length) {
            return false;
        } else if (dplaces.length === 1 /*&& !(grid.get(dplaces[0]) === hintId) && !(parsedGrid.get(dplaces[0]).size === 1)*/) {
            /* Смысл тут такой, что если в юните осталось только одно место для заданного значения, то ему там и быть,
             * что логично - т.е. например в квадрате остались только следующие селы 68 68 168, очевидно, что 1 может быть
             * только в последнем - вот почему dplaces.length === 1. Два дополнительных условия, чтобы сократить число
             * вызовов Assign
             *
             * На тестовой раскладке без них количество вызовов 264
             *
             * Вводим второе условие, оно означает, что не нужно вызывать Assign по этому правилу для заранее заданных
             * значений, мы и так уже по ним проходимся и без сопливых знаем, что они могут быть только тут - количество
             * вызовов 172
             *
             * Вводим третье условие - количество вызовов 78
             * Смысл в том, что если мы эту ячейку уже assign или в этом цикле раньше, то больше ее не надо трогать
             */

            if (!assign(parsedGrid, dplaces[0], hintId)) {
                return false;
            }
        }
    }

    return true;
};

const assign = (parsedGrid, cellId, cellValue, grid = null) => {
    const restOfHints = [...parsedGrid.get(cellId)].filter(hintId => hintId !== cellValue);

    for (const hintId of restOfHints) {
        if (!eliminate(parsedGrid, cellId, hintId, grid)) {
            return false;
        }
    }

    return parsedGrid;
};

const removeNaked = (parsedGrid, level, gridToSolve, bReport = false, strategiesCounter = undefined) => {
    let naked = undefined;

    if (level === 1) {
        naked = 1;
    }

    if (level === 3) {
        naked = 2;
    }

    if (level === 4) {
        naked = 3;
    }

    if (level === 9) {
        naked = 4;
    }

    if (!naked) {
        return null;
    }

    let gridHasChanged = false;
    let uniquepairs = new Set();

    if (naked === 1) {
        const nakedCells = new Map();
        const cellsToEliminate = new Map();

        unitlist.forEach((unit, index) => {
            const filteredUnit = unit.filter(cellId => gridToSolve.get(cellId) === '0' && parsedGrid.get(cellId).size === 1);
            const unfilledCells = unit.filter(cellId => gridToSolve.get(cellId) === '0');

            if (filteredUnit.length) {
                filteredUnit.forEach(cellId => {
                    const hintId = [...parsedGrid.get(cellId)][0];
                    let willEliminate = false;

                    unfilledCells.filter(unfilledCellId => unfilledCellId !== cellId).forEach(unfilledCellId => {
                        if (parsedGrid.get(unfilledCellId).has(hintId)) {
                            cellsToEliminate.set(unfilledCellId, hintId);
                            willEliminate = true;
                        }
                    });

                    if (willEliminate || bReport) {
                        nakedCells.set(cellId, { hintId, unit, unitIndex: index });
                    }
                });
            }
        });

        if (bReport) {
            return nakedCells.size ? nakedCells : undefined;
        }

        for (const [cellId, hintId] of cellsToEliminate) {
            gridHasChanged = true;

            if (!eliminate(parsedGrid, cellId, hintId, gridToSolve)) {
                return false;
            }
        }

        if (gridHasChanged) {
            if (strategiesCounter) {
                strategiesCounter.naked += nakedCells.size;
                strategiesCounter.iterations += 1;
                strategiesCounter.steps.push({ strategy: 'naked', count: nakedCells.size});
            }

            parsedGrid = reparse(parsedGrid, level, gridToSolve, strategiesCounter);
        }

        return parsedGrid;
    }

    for (const [unitIndex, unit] of unitlist.entries()) {
    //unitlist.forEach(unit => {
        /*
         * 1. Сначала фильтруем: Pairs берем все ячейки, где 2 хинта; Triples 2 или 3 хинта; Quads 2, 3 или 4 хинта
         * 2. Но если все остальные ячейки уже заполненны, нам неинтересно: Pairs 7 заполненных ячеек; Triples 6; Quads 5
         * 3. Начинаем бегать по ячейкам с максимальным количеством нужных нам хинтов: Pairs 2, Triples 3; Quads 4
         * 4. Например для triple у нас в ячейках 37 137 137: получается мы два раз пробежим 137
         *    и получим две одинаковые троицы (137 137 37) (137 37 137), но потом все отфильтруем
         */
        const filteredUnit = unit.filter(cellId => parsedGrid.get(cellId).size > 1 && parsedGrid.get(cellId).size <= naked);
        const unfilledCells = unit.filter(cellId => parsedGrid.get(cellId).size === 1).length;

        if (filteredUnit.length >= naked && unfilledCells !== (9 - naked)) {
            const nakedCells = new Map();

            filteredUnit.forEach(cellId => {
                filteredUnit.filter(cellId2 => cellId2 !== cellId).forEach(cellId2 => {
                    const twoCellsHints = new Set([...parsedGrid.get(cellId), ...parsedGrid.get(cellId2)]);

                    if (naked === 2) {
                        if (twoCellsHints.size === 2) {
                            // Found naked pair !
                            nakedCells.set([cellId, cellId2].sort().join(''), {
                                hints: twoCellsHints,
                                cells: new Set([cellId, cellId2]),
                                unit,
                                unitIndex
                            });
                        }
                    } else {
                        filteredUnit.filter(cellId3 => cellId3 !== cellId2 && cellId3 !== cellId && cellId2 !== cellId).forEach(cellId3 => {
                            const threeCellsHints = new Set([...parsedGrid.get(cellId), ...parsedGrid.get(cellId2), ...parsedGrid.get(cellId3)]);

                            if (naked === 3) {
                                if (threeCellsHints.size === 3) {
                                    // Found naked triple !
                                    nakedCells.set([cellId, cellId2, cellId3].sort().join(''), {
                                        hints: threeCellsHints,
                                        cells: new Set([cellId, cellId2, cellId3]),
                                        unit,
                                        unitIndex
                                    });
                                }
                            } else {
                                filteredUnit.filter(cellId4 =>
                                    cellId3 !== cellId2
                                    && cellId3 !== cellId
                                    && cellId2 !== cellId
                                    && cellId4 !== cellId3
                                    && cellId4 !== cellId2
                                    && cellId4 !== cellId
                                ).forEach(cellId4 => {
                                    const threeCellsHints = new Set([...parsedGrid.get(cellId), ...parsedGrid.get(cellId2), ...parsedGrid.get(cellId3), ...parsedGrid.get(cellId4)]);

                                    if (naked === 4) {
                                        if (threeCellsHints.size === 4) {
                                            // Found naked quad !
                                            nakedCells.set([cellId, cellId2, cellId3, cellId4].sort().join(''), {
                                                hints: threeCellsHints,
                                                cells: new Set([cellId, cellId2, cellId3, cellId4]),
                                                unit,
                                                unitIndex
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            });

            if (nakedCells.size) {
                for (let nakedEntry of nakedCells) {
                    const nakedGroup = nakedEntry[1];

                    const candidateCells = unit.filter(cellId => parsedGrid.get(cellId).size > 1 && !nakedGroup.cells.has(cellId));

                    for (let candidateCellId of candidateCells) {
                        for (let hintId of nakedGroup.hints) {
                            if (parsedGrid.get(candidateCellId).has(hintId)) {
                                if (bReport) {
                                    nakedGroup.hintId = hintId;
                                    return nakedGroup;
                                }

                                uniquepairs.add([...nakedGroup.cells].sort().join(''));
                                gridHasChanged = true;

                                if (!eliminate(parsedGrid, candidateCellId, hintId, gridToSolve)) {
                                    //console.log(level, parsedGrid, candidateCellId, hintId, gridToSolve);
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    if (bReport) {
        return undefined;
    }

    if (gridHasChanged) {
        if (strategiesCounter) {
            if (naked === 2) {
                strategiesCounter.nakedpair += uniquepairs.size;
                strategiesCounter.steps.push({ strategy: 'nakedpair', count: uniquepairs.size});
            } else if (naked === 3) {
                strategiesCounter.nakedtriple += uniquepairs.size;
                strategiesCounter.steps.push({ strategy: 'nakedtriple', count: uniquepairs.size});
            } else if (naked === 4) {
                strategiesCounter.nakedquad += uniquepairs.size;
                strategiesCounter.steps.push({ strategy: 'nakedquad', count: uniquepairs.size});
            }

            strategiesCounter.iterations += 1;
        }

        parsedGrid = reparse(parsedGrid, level, gridToSolve, strategiesCounter);
    }

    return parsedGrid;
};

const removeRowsPointingPairs = (parsedGrid, level, gridToSolve, bReport = false, strategiesCounter = undefined) => {
    //let gridHasChanged = false;
    let cellsToProcess = [];
    let uniquepointingrows = new Set();

    for (const [unitIndex, unit] of unitlist.entries()) {
        const filteredUnit = unit.filter(cellId => parsedGrid.get(cellId).size > 1);

        if (filteredUnit.length > 2) {
            if (unitIndex < 9) {
                const counterH = new Map();

                /* A1 A2 A3 B1 B2 B3 C1 C2 C3, ... , G7 G8 G9 H7 H8 H9  */

                /*
                * 2 9 3
                * 7 8 56
                * 1 456 456
                * Нам нужно найти нижние две четверки
                *
                * counterH = {
                *    '5' => {
                         'rows' => [ 2 3 ]
                         'cells' => [ B3 C2 C3 ]
                *    }
                *    '6' => {
                *        'rows' => [ 2 3 ]
                *        'cells' => [ B3 C2 C3 ]
                *    }
                *    '4' => {
                *        'rows' => [ 3 ]
                *        'cells' => [ C2 C3 ]
                *    }
                * }
                * Таким образом на нужно посмотреть, что 4 встречается только в третьей строчке в ячейках C2 и C3,
                * а значит потенициальный pointing pair для unitа C1 C2 C3 C4 C5 C6 C7 C8 C9
                * */

                filteredUnit.forEach(cellId => {
                    parsedGrid.get(cellId).forEach(hintId => {
                        const cellIndex = unit.indexOf(cellId);
                        const rowNumber = Math.ceil((cellIndex + 1) /3);//cellIndex < 3 ? 1 : (cellIndex < 5 ? 2 : 3);

                        const hint = counterH.get(hintId) || new Map();
                        const rows = hint.get('rows') || new Set();
                        const cells = hint.get('cells') || new Set();

                        cells.add(cellId);
                        rows.add(rowNumber);

                        hint.set('rows', rows);
                        hint.set('cells', cells);

                        counterH.set(hintId, hint);
                    });
                });

                /* У нас не может быть counterH с меньше чем двумя строками, так как мы предполагает, что
                *  все naked мы уже убрали,
                *  к сожалению остаются случаи, когда заполненно три по вертикали (ну и ладно), например
                *
                * 6 7 59
                * 3 2 159
                * 4 8 159
                */

                for (let hints of counterH) {
                    /* Проверим, что данный хинт встречается только в одной строчке */
                    if (hints[1].get('rows').size === 1) {
                        /* Теперь нам нужно найти нужный нам row юнит, как мы знаем у них в unitlist индекс от 9 до 17 */
                        const pointingHint = hints[0];
                        const cellsWithPointingHint = [ ...hints[1].get('cells') ].sort();

                        for (let index = 9; index < 18; index++) {
                            /* достаточно проверить наличие только одной ячейки */
                            if (unitlist[index].indexOf(cellsWithPointingHint[0]) !== -1) {
                                const cellsToCheckForElimitation = unitlist[index].filter(cellId => cellsWithPointingHint.indexOf(cellId) === -1 && parsedGrid.get(cellId).size > 1);

                                if (cellsToCheckForElimitation.length) {
                                    for (const cellId of cellsToCheckForElimitation) {
                                        if (parsedGrid.get(cellId).has(pointingHint)) {
                                            if (bReport) {
                                                return {
                                                    pointingHint,
                                                    pointingUnit: unit,
                                                    pointingUnitIndex: unitIndex,
                                                    cellsWithHints: hints[1].get('cells'),
                                                    unitWithHintsToRemove: unitlist[index],
                                                    unitIndexWithHintsToRemove: index,
                                                    cellToEliminate: cellId
                                                };
                                            }

                                            uniquepointingrows.add(cellsWithPointingHint.join(''));

                                            /* WE FOUND! */
                                            //gridHasChanged = true;

                                            cellsToProcess.push({
                                                cellId, pointingHint
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    if (bReport) {
        return undefined;
    }


    if (cellsToProcess.length) {
        for (const {cellId, pointingHint} of cellsToProcess) {
            if (!eliminate(parsedGrid, cellId, pointingHint, gridToSolve)) {
                return false;
            }
        }

        if (strategiesCounter) {
            strategiesCounter.rowpointingpair += uniquepointingrows.size;
            strategiesCounter.iterations += 1;
            strategiesCounter.steps.push({ strategy: 'rowpointingpair', count: uniquepointingrows.size});
        }


        parsedGrid = reparse(parsedGrid, level, gridToSolve, strategiesCounter);
    }

    return parsedGrid;
};

const removeColumnsPointingPairs = (parsedGrid, level, gridToSolve, bReport = false, strategiesCounter = undefined) => {
    let cellsToProcess = [];
    let uniquepointingcolumns = new Set();

    for (const [unitIndex, unit] of unitlist.entries()) {
    //unitlist.forEach((unit, index) => {
        const filteredUnit = unit.filter(cellId => parsedGrid.get(cellId).size > 1);

        if (filteredUnit.length > 2) {
            if (unitIndex < 9) {
                const counterV = new Map();

                /*
                * 5 8 379
                * 367 2 1379
                * 367 4 1379
                * Нам нужно найти нижние две шестерки,три правые девятки и две правые единицы
                *
                * counterV = {
                *    '3' => {
                *        'columns' => [ 2 3 ]
                *        'cells' => [ A3 B1 B3 C1 C3 ]
                *    }
                *    '7' => {
                *        'columns' => [ 2 3 ]
                *        'cells' => [ A3 A1 B1 B3 C1 C3 ]
                *    }
                *    '9' => {
                *        'columns' => [ 3 ],
                *        'cells' => [ A3 B3 C3 ]
                *    }
                *    '6' => {
                *        'columns' => [ 1 ]
                *        'cells' => [ B1 C1 ]
                *    }
                *    '1' => {
                *        'columns' => [ 3 ]
                *        'cells' => [ B3 C3 ]
                *    }
                * }
                */

                filteredUnit.forEach(cellId => {
                    parsedGrid.get(cellId).forEach(hintId => {
                        const cellIndex = unit.indexOf(cellId);
                        const rowColumn = cellIndex % 3 + 1;//cellIndex < 3 ? 1 : (cellIndex < 5 ? 2 : 3);

                        const hint = counterV.get(hintId) || new Map();
                        const columns = hint.get('columns') || new Set();
                        const cells = hint.get('cells') || new Set();

                        cells.add(cellId);
                        columns.add(rowColumn);

                        hint.set('columns', columns);
                        hint.set('cells', cells);

                        counterV.set(hintId, hint);
                    });
                });

                for (let hints of counterV) {
                    /* Проверим, что данный хинт встречается только в одной строчке */
                    if (hints[1].get('columns').size === 1) {
                        /* Теперь нам нужно найти нужный нам row юнит, как мы знаем у них в unitlist индекс от 9 до 17 */
                        const pointingHint = hints[0];
                        const cellsWithPointingHint = [...hints[1].get('cells')].sort();

                        for (let index = 18; index < 27; index++) {
                            if (unitlist[index].indexOf(cellsWithPointingHint[0]) !== -1) {
                                const cellsToCheckForElimitation = unitlist[index].filter(cellId => cellsWithPointingHint.indexOf(cellId) === -1 && parsedGrid.get(cellId).size > 1);

                                if (cellsToCheckForElimitation.length) {
                                    for (const cellId of cellsToCheckForElimitation) {
                                    //cellsToCheckForElimitation.forEach(cellId => {
                                        if (parsedGrid.get(cellId).has(pointingHint)) {
                                            if (bReport) {
                                                return {
                                                    pointingHint,
                                                    pointingUnit: unit,
                                                    pointingUnitIndex: unitIndex,
                                                    cellsWithHints: hints[1].get('cells'),
                                                    unitWithHintsToRemove: unitlist[index],
                                                    unitIndexWithHintsToRemove: index,
                                                    cellToEliminate: cellId
                                                };
                                            }

                                            uniquepointingcolumns.add(cellsWithPointingHint.join(''));
                                            /* WE FOUND! */
                                            cellsToProcess.push({
                                                cellId, pointingHint
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    if (bReport) {
        return undefined;
    }

    if (cellsToProcess.length) {
        for (const {cellId, pointingHint} of cellsToProcess) {
            if (!eliminate(parsedGrid, cellId, pointingHint, gridToSolve)) {
                return false;
            }
        }

        if (strategiesCounter) {
            strategiesCounter.columnpointingpair += uniquepointingcolumns.size;
            strategiesCounter.iterations += 1;
            strategiesCounter.steps.push({ strategy: 'columnpointingpair', count: uniquepointingcolumns.size});
        }

        parsedGrid = reparse(parsedGrid, level, gridToSolve, strategiesCounter);
    }

    return parsedGrid;

    //000000018700040000000000030420000700000001000000300000500070200601800000040000000 // box-line
    //000000051020600000000000000070000200300050000000040800501000030400008000000200600 // horizontal
    //000000072080500000010000000200097000000000100300000000703000060000180500000400000
    //000000072080500000010000000200097000000000800600000000703000060000180500000400000 vertical
};

const removeBoxLineReduction = (parsedGrid, level, gridToSolve, bReport = false, strategiesCounter = undefined) => {
    let cellsToProcess = [];
    let uniquepointing = new Set();

    if ((level !== 7) && (level !== 8)) {
        return null;
    }

    /* Row box/line reduction - A1 A2 ... A9 ... I1 I2 ... I9 */
    let unitListFrom = 9;
    let unitListTo = 18;

    /* Column box/line reduction - A1 B1 ... I1 ... I9 B9 ... I9 */
    if (level === 8) {
        unitListFrom = 18;
        unitListTo = 27;
    }

    /* У нас есть строка:
    * 4 2 3    569 9 569    7 59 1
    * 6-ки посередине - кандидаты на BoxLineReduction
    * counter = {
    *     '5' => {
    *         'squares' => [ 2 3 ],
    *         'cells' => [A4 A6 A8]
    *     },
    *     '6' => {
    *         'squares' => [ 2 ],
    *         'cells' => [A4 A6]
    *     }
    *     '9' => {
    *          'squares' => [ 2 3 ],
    *          'cells' => [A4 A6 A8]
    *     }
    * }
    *     Теперь мы можем вытащить 6-ку, она только во втором квадрате
    * */
    for (let index = unitListFrom; index < unitListTo; index++) {
        const counter = new Map();

        unitlist[index].forEach((cellId, cellIndex) => {
            const squareNumber = Math.floor(cellIndex/3) + 1;
            const cellHints = parsedGrid.get(cellId);

            if (cellHints.size > 1) {
                cellHints.forEach(hintId => {
                    const hint = counter.get(hintId) || new Map();
                    const squares = hint.get('squares') || new Set();
                    const cells = hint.get('cells') || new Set();

                    cells.add(cellId);
                    squares.add(squareNumber);

                    hint.set('squares', squares);
                    hint.set('cells', cells);
                    hint.set('columnOrRowIndex', index);

                    counter.set(hintId, hint);
                });
            }
        });

        if (counter.size) {
            for (let hints of counter) {
                if (hints[1].get('squares').size === 1) {
                    const pointingHint = hints[0];
                    const cellsWithPointingHint = [...hints[1].get('cells')].sort();

                    const {
                        index: unitIndexToCheck,
                        unit: unitToCheck
                    } = unitlist.filter((unit, index) => index < 9).reduce((accumulator, unit, index) => {
                        for (let i = 0; i < cellsWithPointingHint.length; i++) {
                            if (unit.indexOf(cellsWithPointingHint[i]) === -1) {
                                return accumulator;
                            }
                        }

                        accumulator.index = index;
                        accumulator.unit = unit;

                        return accumulator;
                    }, {});

                    const cellsToCheckForElimitation = unitToCheck.filter(cellId => cellsWithPointingHint.indexOf(cellId) === -1 && parsedGrid.get(cellId).size > 1);

                    if (cellsToCheckForElimitation.length) {
                        for (const cellId of cellsToCheckForElimitation) {
                            if (parsedGrid.get(cellId).has(pointingHint)) {
                                /* WE FOUND! */

                                if (bReport) {
                                    return {
                                        pointingHint,
                                        pointingCells: hints[1].get('cells'),
                                        pointingUnit: unitlist[hints[1].get('columnOrRowIndex')],
                                        pointingUnitIndex: hints[1].get('columnOrRowIndex'),
                                        boxIndex: unitIndexToCheck,
                                        box: unitToCheck,
                                        cellToEliminate: cellId
                                    };
                                }

                                uniquepointing.add(cellsWithPointingHint.join(''));

                                cellsToProcess.push({
                                    cellId, pointingHint
                                });
                            }
                        }
                    }
                }
            }
        }
    }

    if (bReport) {
        return undefined;
    }

    if (cellsToProcess.length) {
        for (const {cellId, pointingHint} of cellsToProcess) {
            if (!eliminate(parsedGrid, cellId, pointingHint, gridToSolve)) {
                return false;
            }
        }

        if (strategiesCounter) {
            if (level === 7) {
                strategiesCounter.rowboxline += uniquepointing.size;
                strategiesCounter.steps.push({ strategy: 'rowboxline', count: uniquepointing.size});
            } else {
                strategiesCounter.columnboxline += uniquepointing.size;
                strategiesCounter.steps.push({ strategy: 'columnboxline', count: uniquepointing.size});
            }

            strategiesCounter.iterations += 1;
        }

        parsedGrid = reparse(parsedGrid, level, gridToSolve, strategiesCounter);
    }

    return parsedGrid;
};

const removeHidden = (parsedGrid, level, gridToSolve, bReport = false, strategiesCounter = undefined) => {
    /*
    * HIDDEN TRIPLE
    *
    * 357  358    4578
    * 1    25689  4578
    * 2379 23689  478
    *
    * Нужно выцепить 269 29 269
    *  1: hintCounter
    *
    * '2' => [ B2 C1 C2 ]
    * '3' => [ A1 A2 B1 B2 ]
    * '4' => [ A3 C3 B3 ]
    * '5' => [ A1 A2 A3 B2 B3 ]
    * '6' => [ B2 C2 ]
    * '7' => [ A1 A3 B3 C1 C3 ]
    * '8' => [ A2 A3 B2 B3 C2 C3 ]
    * '9' => [ B2 C2 C3 ]
    *
    * 2: Уберем все, где больше трех ячеек
    *
    *  '2' => [ B2 C1 C2 ]
    *  '4' => [ A3 C3 B3 ]
    *  '6' => [ B2 C2 ]
    *  '9' => [ B2 C1 C2 ]
    *
    * 3: Возьмем все cell с длиной три и пойдем по ним циклом:
    *
    *  итерация 1: '2'
    *     '4' - A3 C3 B3 не входят в B2 C1 C2
    *     '6' - B2 C2 входят в B2 C1 C2
    *     '9' - B2 C1 C2 входят в B2 C1 C2
    *     => тройка найдена '2' '6' '9'
    *     делаем мэп [ '269' => [B2 C1 C2] ]
    *  итерация 2: '4'
    *     => не входит никуда
    *  итерация 3: '9'
    *     => как и на первой итерации получили тройку '9' '6' '2' и соответсвующий ей набор [ B2 C1 C2 ]
    *     => добавляем в сэт, но там такое уже есть, поэтому получается перезапись, но для простоты алгоритма
    *        и чисто теоретической возможности двух хидден триппл в юните оставим as is
    * 4. Получаем итоговый мэп:
    *    '269' => {
    *        cells: [ B2 C1 C2 ]
    *        hints [ 2 6 9 ]
    *    }
    * 5. Бежим по B2 C1 C2 и удаляем все что не в сете хинтов
    * */

    // 901500046425090081860010020502000000019000460600000002196040253200060817000001694 Hidden quad

    let hidden;
    let uniquehiddengroup = new Set();

    if (level === 2) {
        hidden = 1;
    }

    if (level === 10) {
        hidden = 2;
    }

    if (level === 11) {
        hidden = 3;
    }

    if (level === 12) {
        hidden = 4;
    }

    let gridHasChanged = false;

    if (hidden === 1) {
        const cellsWithHiddenSingles = new Map();

        unitlist.forEach((unit, unitIndex) => {
            const unfilledCells = unit.filter(cellId => gridToSolve.get(cellId) === '0');

            unit.filter(cellId => parsedGrid.get(cellId).size > 1).forEach(cellId => {
                const hiddenHints = [...parsedGrid.get(cellId)].filter(hintId => {
                    const unfilledCellsWithHint = unfilledCells.filter(unfilledCellsId => unfilledCellsId !== cellId && parsedGrid.get(unfilledCellsId).has(hintId));
                    // !2 = false !0 = true
                    return !unfilledCellsWithHint.length;
                });

                if (hiddenHints.length === 1) {
                    cellsWithHiddenSingles.set(cellId, { hintId: hiddenHints[0], unit, unitIndex });
                }
            });
        });

        if (bReport) {
            return cellsWithHiddenSingles.size ? cellsWithHiddenSingles : undefined;
        }

        for (let cellWithHiddenSingles of cellsWithHiddenSingles) {
            const [
                cellId,
                {
                    hintId,
                    unit
                }
            ] = cellWithHiddenSingles;

            gridHasChanged = true;

            const hintsToEliminate = [...parsedGrid.get(cellId)].filter(filteredHintId => filteredHintId !== hintId);

            for (let hintIdToEliminate of hintsToEliminate) {
                if (!eliminate(parsedGrid, cellId, hintIdToEliminate, gridToSolve)) {
                    return false;
                }
            }

            /*
            * Мы уже знаем, что юнит с хидден синглом нам проверять не нужно,
            * на то это и сингл
            *
            * unit = new Set(['a1','a2'])
            * peers = new Set(['a1','a2','a3','a4','a5'])
            * unit.forEach(peers.delete, peers)
            *
            * >> peers = Set [ "a3", "a4", "a5" ]
            *
            * */
            const peersToCheck = new Set([...peers[cellId]]);
            unit.forEach(peersToCheck.delete, peersToCheck);
            const cellsToEliminate = [...peersToCheck].filter(cellId => parsedGrid.get(cellId).has(hintId));

            for (const peerCellId of cellsToEliminate) {
                if (!eliminate(parsedGrid, peerCellId, hintId, gridToSolve)) {
                    return false;
                }
            }
        }

        if (gridHasChanged) {
            if (strategiesCounter) {
                strategiesCounter.hidden += cellsWithHiddenSingles.size;
                strategiesCounter.iterations += 1;
                strategiesCounter.steps.push({ strategy: 'hidden', count: cellsWithHiddenSingles.size});
            }

            parsedGrid = reparse(parsedGrid, level, gridToSolve, strategiesCounter);
        }

        return parsedGrid;
    }

    for (const [unitIndex, unit] of unitlist.entries()) {
        const filteredUnit = unit.filter(cellId => parsedGrid.get(cellId).size > 1);

        if (filteredUnit.length > hidden) {
            const hintCounter = new Map();

            /* Шаг 1 */
            filteredUnit.forEach(cellId => {
                parsedGrid.get(cellId).forEach(hintId => {
                    const cells = hintCounter.get(hintId) || new Set();
                    cells.add(cellId);
                    hintCounter.set(hintId, cells);
                });
            });

            const hintsToIterate = [];

            /* Шаг 2 */
            for (const [hintId, cells] of hintCounter) {
                if (cells.size > hidden || cells.size === 1) {
                    hintCounter.delete(hintId);
                } else if (cells.size === hidden) {
                    hintsToIterate.push(hintId);
                }
            }

            /* Шаг 3 */
            const hiddenHints = new Map();

            // 134689 378 34689
            // 238 23578 2358
            // 13489 3578 34589

            // hintCounter
            // 1 → D4 F4
            // 4 → D4 D6 F4 F6
            // 6 → D4 D6
            // 9 → D4 D6 F4 F6
            // 7 → D5 E5 F5
            // 2 → E4 E5 E6
            // 5 → E5 E6 F5 F6

            // hintToIterate -> 4 9 5

            hintsToIterate.forEach(hintId => {
                const candidates = new Map();
                candidates.set(hintId, hintCounter.get(hintId));

                for (const [searchHintId, cells] of hintCounter) {
                    if (searchHintId !== hintId) {
                        if ((new Set([...hintCounter.get(hintId), ...cells])).size === hidden) {
                            candidates.set(searchHintId, cells);
                        }
                    }
                }

                if (candidates.size === hidden) {
                    hiddenHints.set([...candidates.keys()].sort().join(''), {
                        cells: hintCounter.get(hintId),
                        hints: new Set(candidates.keys()),
                        unit,
                        unitIndex
                    });
                }
            });

            if (hiddenHints.size) {
                for (const [groupId, hiddenGroup] of hiddenHints) {
                    for (const cellId of hiddenGroup.cells) {
                        for (const hintId of parsedGrid.get(cellId)) {
                            if (!hiddenGroup.hints.has(hintId)) {
                                if (bReport) {
                                    return hiddenGroup;
                                }
                                uniquehiddengroup.add(groupId);
                                gridHasChanged = true;

                                if (!eliminate(parsedGrid, cellId, hintId, gridToSolve)) {
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    if (bReport) {
        return undefined;
    }

    if (gridHasChanged) {
        if (strategiesCounter) {
            if (hidden === 2) {
                strategiesCounter.hiddenpair += uniquehiddengroup.size;
                strategiesCounter.steps.push({ strategy: 'hiddenpair', count: uniquehiddengroup.size});
            } else if (hidden === 3) {
                strategiesCounter.hiddentriple += uniquehiddengroup.size;
                strategiesCounter.steps.push({ strategy: 'hiddentriple', count: uniquehiddengroup.size});
            } else if (hidden === 4) {
                strategiesCounter.hiddenquad += uniquehiddengroup.size;
                strategiesCounter.steps.push({ strategy: 'hiddenquad', count: uniquehiddengroup.size});
            }

            strategiesCounter.iterations += 1;
        }

        parsedGrid = reparse(parsedGrid, level, gridToSolve, strategiesCounter);
    }

    return parsedGrid;
};

/**
 * 1. Имеем грид заданнных значение и грид, заполненнный подсказками, где в каждой ячейке сет из подсказок 1-9
 *    0 0 3 0 5 ...
 *    123456789 123456789 123456789 123456789 123456789 ...
 * 2. Идем циклом по гриду: 0 - пропускаем, 0 - пропускаем, 3 - запускаем assign
 * 3. В assign бежим циклом по всем хинтам за исключением известного нам значения 3: 12 456789
 * 4. Идем по оставшимся подсказкам пробегаем eliminate, в которой по очереди удаляем хинты
 *    123456789 -> 23456789 -> 3456789 -> 356789 -> 36789 -> 3789 -> 389 -> 39 -> 3
 * 5. Остался ровно один хинт, он же известное нам заранее значение (по сути 8 ненужных проходов цикла, т.к. нам и так было известно значение)
 *    Вот тут срабатывает if (hints.size === 1) и мы делаем eliminate (т.е. удаляем из подсказок) этого значения для всех пиров
 *    Тут возможно рекурсия, если у какого-то из пиров в свою очередь осталось только одно значение хинта, он в свою очередь запускает eliminate
 * 6. На шаг 2
 *
 *
 * @param rawLayout
 * @param parse
 * @returns {Map.<String, Set>}
 */
const parseGrid = (rawLayout, parse = true) => {
    const grid = convertRawLayoutToGrid(rawLayout);
    const parsedGrid = new Map();
    const digits = new Set(['1', '2', '3', '4', '5', '6', '7', '8', '9']);
    cells.forEach(cellId => parsedGrid.set(cellId, new Set(['1', '2', '3', '4', '5', '6', '7', '8', '9'])));

    for (const [cellId, cellValue] of grid.entries()) {
        if (digits.has(cellValue) && !assign(parsedGrid, cellId, cellValue, parse ? null : grid)) {
            return null;
        }
    }

    return parsedGrid;
};

const strategiesLevels = () => {
    const levels = [
        { strategy: 'naked', difficulty: 'easy', level: 1, parser: removeNaked },
        { strategy: 'hidden', difficulty: 'easy', level: 2, parser: removeHidden },
        { strategy: 'nakedpair', difficulty: 'medium', level: 3, parser: removeNaked },
        { strategy: 'nakedtriple', difficulty: 'medium', level: 4, parser: removeNaked },
        { strategy: 'rowpointingpair', difficulty:  'hard', level: 5, parser: removeRowsPointingPairs },
        { strategy: 'columnpointingpair', difficulty: 'hard', level: 6, parser: removeColumnsPointingPairs },
        { strategy: 'rowboxline', difficulty: 'hard', level: 7, parser: removeBoxLineReduction },
        { strategy: 'columnboxline', difficulty: 'hard', level: 8, parser: removeBoxLineReduction },
        { strategy: 'nakedquads', difficulty: 'hard', level: 9, parser: removeNaked },
        { strategy: 'hiddenpair', difficulty: 'hard', level: 10, parser: removeHidden },
        { strategy: 'hiddentriple', difficulty: 'hard', level: 11, parser: removeHidden },
        { strategy: 'hiddenquad', difficulty: 'hard', level: 12, parser: removeHidden },
    ];

    return levels.sort((a, b) => a.level - b.level);
};

const reparse = (parsedGrid, level, gridToSolve, strategiesCounter = undefined) => {
    if (!parsedGrid || gridSolved(parsedGrid)) {
        return parsedGrid;
    }

    for (const { level: currentLevel, parser } of strategiesLevels()) {
        parsedGrid = parser(parsedGrid, currentLevel, gridToSolve, false, strategiesCounter);

        if (!parsedGrid || level === currentLevel || gridSolved(parsedGrid)) {
            return parsedGrid;
        }
    }

    return parsedGrid;
};

const parseRaw = (rawLayout, level, strategiesCounter = undefined) => {
    const gridToSolve = convertRawLayoutToGrid(rawLayout);
    let parsedGrid = parseGrid(rawLayout, false);

    if (level === 0) {
        return parsedGrid;
    }

    return reparse(parsedGrid, level, gridToSolve, strategiesCounter);
};

const evaluateRaw = rawLayout => {
    const getStrategiesCounter = () => {
        const strategiesCounter = {
            'iterations': 0,
            'steps': []
        };

        for (const { strategy } of strategiesLevels()) {
            strategiesCounter[strategy] = 0;
        }

        return strategiesCounter;
    };

    const reevaluate = rawLayout => {
        const predefined = Array.from(rawLayout).filter(char => char !== '0').length;
        let strategiesCounter;

        for (const { strategy, difficulty, level } of strategiesLevels()) {
            strategiesCounter = getStrategiesCounter();
            const parsedGrid = parseRaw(rawLayout, level, strategiesCounter);

            if (!parsedGrid) {
                return {
                    strategy,
                    difficulty,
                    predefined,
                    level,
                    status: `incorrect grid at level ${level}`,
                    strategiesCounter,
                };
            }

            if (gridSolved(parsedGrid)) {
                const flattenedParsedGrid = [...parsedGrid.values()].map(hints => [...hints][0]).join('');
                const brutforced = [...solveSudoku(rawLayout).values()].map(hints => [...hints][0]).join('');

                return {
                    strategy,
                    difficulty,
                    predefined,
                    level,
                    status: (brutforced !== flattenedParsedGrid) ? `brutforced doesn't match parsed ${strategy} (level ${level})` : null,
                    strategiesCounter,
                    solution: brutforced
                };
            }
        }

        return {
            strategy: null,
            difficulty: 'expert',
            predefined,
            level: null,
            status: null,
            strategiesCounter,
            solution: [...solveSudoku(rawLayout).values()].map(hints => [...hints][0]).join('')
        };
    };

    const res = reevaluate(rawLayout);

    if ((res.predefined === 17 || res.predefined === 18) && res.difficulty === 'easy') {
        res.difficulty = 'medium';
    }

    return res;
};

const gridSolved = parsedGrid => {
    /* После распарсивания уже получили решение -> гадать не нужно
     *
     * Вот эту бяку не будем использовать
     *    if (all(cells.map(s => parsedGrid.get(s).size === 1)))
     * потому что для каждой ячейки происходит поиск размера,
     * а в дубовом способе ниже останавливаемся, как только нашли первую ячейку с более чем одним хинтом
     */

    let alreadySolved = true;

    for (const cellId of cells) {
        if (parsedGrid.get(cellId).size > 1) {
            alreadySolved = false;
            break;
        }
    }

    return alreadySolved;
};

/**
 * 1. Получаем после распарсивания неразложенный грид, там например в ячейках осталось 34 561 19
 * 2. Смотрим ячейку где количество вариантов минимально: 34 и 19
 * 3. Берем 34 - берем 3 и пытаемся насильно решить - если это неправильно значение, то и грид не разрешится,
 *    а следовательно из assign вернется false
 * 4. Берем 4 - оно уже точно подойдет, после этого грид может сам собой решиться или если останутся нерешенные
 *    ячейки, берем следующую пару - 19 и повторяем снова подстановку
 * 5. Такая рекурсия до тех пор пока не разрешим все ячейки
 *
 * @param parsedGrid
 * @returns {*}
 */
const searchSolution = parsedGrid => {
    /* После распарсивания ничего невернулось -> некорректный грид */
    if (!parsedGrid) {
        return false;
    }

    if (gridSolved(parsedGrid)) {
        return parsedGrid;
    }

    const cellToBruteforce = cells
        .filter(s => parsedGrid.get(s).size > 1)
        .sort((s1, s2) => parsedGrid.get(s1).size - parsedGrid.get(s2).size)[0];

    for (const hintId of (new Set(parsedGrid.get(cellToBruteforce)))) {

        const newGridToParse = new Map(parsedGrid);

        /* мутабелность, xoxoxo */
        for (const [cellId, cellHints] of newGridToParse) {
            newGridToParse.set(cellId, new Set(cellHints));
        }

        const resultedGrid = searchSolution(assign(newGridToParse, cellToBruteforce, hintId));

        if (resultedGrid) {
            return resultedGrid;
        }
    }

    return false;
};

const solveSudoku = rawLayout => {
    return searchSolution(parseGrid(rawLayout));
};

/*
const iterateParsedGrid = (parsedGrid, status) => {
    const cellsToIterate = cells.filter(s => parsedGrid.get(s).size > 1);

    cellsToIterate.forEach(cellId => {
        for (const hintId of (new Set(parsedGrid.get(cellId)))) {
            if (!(status.checked.has(cellId) && status.checked.get(cellId).has(hintId))) {

                let newGridToParse = new Map(parsedGrid);

                for (const [cellId, cellHints] of newGridToParse) {
                    newGridToParse.set(cellId, new Set(cellHints));
                }

                status.iterations++;

                console.log(status.iterations);

                newGridToParse = assign(newGridToParse, cellId, hintId);

                if (!newGridToParse) {
                    const cellStatus = status.checked.get(cellId) || new Set();
                    cellStatus.add(hintId);
                    status.checked.set(cellId, cellStatus);
                }

                if (newGridToParse && !gridSolved(newGridToParse)) {
                    newGridToParse = reparse(newGridToParse, 9);
                }

                if (newGridToParse && gridSolved(newGridToParse)) {
                    status.solutions.add([...newGridToParse.values()].map(hints => [...hints][0]).join(''));
                } else if (newGridToParse) {
                    iterateParsedGrid(newGridToParse, status);
                }

            }
        }
    });
    return status;
};


const checkRaw = rawLayout => {
    const status = {
        iterations: 0,
        solutions: new Set(),
        evaluation: {},
        checked: new Map()
    };

    const parsedGrid = parseRaw(rawLayout, 9);

    if (gridSolved(parsedGrid)) {
        return {
            iterations: 1,
            solutions: [
                [...parsedGrid.values()].map(hints => [...hints][0]).join('')
            ],
            evaluation: evaluateRaw(rawLayout)
        };
    }

    return iterateParsedGrid(parsedGrid, status);
};
 */

/*const checkNewValueIsWrong = (cell, value, predefinedValues, solutionValues) => {
    if (solutionValues) {
        if (solutionValues[cell] && value !== solutionValues[cell]) {
            return true;
        } else if (solutionValues[cell] && value === solutionValues[cell]) {
            return false;
        }
    }

    for (const peer of peers[cell]) {
        if (predefinedValues[peer] === value) {
            return true;
        }
    }

    return false;
};*/

export {
    solveSudoku,
    parseRaw,
    evaluateRaw,
    getHint
};
